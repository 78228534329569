/* eslint-disable @next/next/no-img-element */


//  import {useRef} from "react"
const Footer: React.FC = () => {
  

   return (
     <div>

      <div id = 'footer' className = "flex flex-col items-center p-6 ">
        <div className = "md:pt-5"> </div>
            <a href = "mailto:contact@aimor.ai" className="font-playfair text-AIMOR_VIOLET_TERTIARY underline font-semibold"> contact@aimor.ai </a>
            <p className="font-playfair text-AIMOR_VIOLET_TERTIARY font-semibold">From San Francisco with love 💜 </p>
            <p className="font-playfair text-AIMOR_VIOLET_TERTIARY font-semibold">© 2023 Aimor BLLC</p>
        <div className = "md:pt-5"> </div>

      </div>
     </div>
   );
 };

export default Footer
 