/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */

import React, { useState, useEffect, } from 'react'
import type { GetServerSidePropsContext } from "next";

// import Head from "next/head";
// import Link from "next/link";
import { useRouter } from 'next/router'
import Image from 'next/image'
// import { styled } from '@mui/material/styles'
import { api } from "@aimor/utils/api";
import logo from "/public/images/logo.svg"
import graphic from "../../public/images/graphic.png"
import Footer from "@aimor/components/Footer"
import { createClient } from "@aimor/utils/supabase/component"
import { createClient as createServerClient } from "@aimor/utils/supabase/server-props"
import Loading from "@aimor/components/Loading"
export default function Home() {

  const router = useRouter();
  const supabase = createClient()

  const [password, setPassword] = useState('')

  const [email, setEmail] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('Please enter your email address');
  const createWaitlistEmailMutation = api.waitlistEmail.addEmailToWaitlist.useMutation();
  const [showButton, setShowButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [signUpExecuted, setSignUpExecuted] = useState(false)
  // Function to validate the email format
  const validateEmail = (email: string) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  useEffect(()=>{
    if (isClicked){
      setTimeout(()=>setIsClicked(false), 3000)
    }
  }, [isClicked])



  function signupRedirect() {
    setIsLoading(true)
    router.push('/signup')
  }
  function guestDashRedirect() {
    setIsLoading(true)
    router.push('/guestDashboard')
  }


   function logInRedirect() {
   setIsLoading(true)
    router.push('/login')
  }


  async function signIn() {
    // console.log('beginning signin with email:', email)
    const { error } = await supabase.auth.signInWithOtp(
      {
        email,
        //  options:{
        //   emailRedirectTo: "http://localhost:3000/userDashboard"
        //  }

      })
    // console.log('within signin function in home, error:', error)
    if (error) {
      // console.log('inerror')
      //put a toast open function here
      console.error(error)
    }
    // console.log('pushing to userdash')
    // router.push('/userDashboard')
  }




  useEffect(() => {
    // console.log( email, isClicked)
    if (email.length > 0) setShowButton(true);

    if (isClicked) setShowButton(true)
  }, [email, isClicked])

  const handleClick = () => {
    handleEmailSubmit()
  }

  // Function to handle form submission
  const handleEmailSubmit = async () => {
    // console.log('handle email', email)
    // Check if the email is valid
    setIsClicked(true)

    if (validateEmail(email)) {
      try {
        // If valid, send the email to the API (you will implement this)
        await createWaitlistEmailMutation.mutateAsync(email)
        // Show success message and clear the text field
        setHelperText(`${email} `);
        setEmail('');
        setError(false);
      }
      catch (error) {
        console.error('Mutation failed', error)
      }
    } else {
      // If not valid, show an error message
      // console.log('invalid email', email)
      setHelperText('Please input a valid email address');
      setError(true);
    }
  };
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await setEmail(event.target.value);
    // console.log("Email updated:", event.target.value)
    // console.log(email)
  };

  // Handler for the Enter key press
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      // console.log('submitting email')
      handleEmailSubmit();
    }
  };

  return (
       <div className=' md:bg-gradient-to-b  md:from-[#5B45FF] md:to-white flex flex-col flex-grow items-center justify-center md:pt-16 overflow-auto '> 
      <div id="homepage-outer" className={`  flex flex-col flex-grow md:w-5/6   md:rounded-3xl md:max-w-4xl   bg-white   pb-6  ${isLoading ? "h-[100vh] w-full" : "" }`} style={{ boxShadow: '0 4px 50px rgba(50, 22, 255, 0.4)' }}>



      {isLoading && <Loading/>}

      {(!isLoading) && 
       <div id="homepage-contents" className="flex flex-col items-center gap-4 shadow-custom">
          {/*eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/}
          <div className = "pb-4"></div>
          <Image src={logo} alt="you should be seeing a logo here" className="h-36" />
          <p className="text-center font-playfair text-title text-AIMOR_VIOLET tracking-widest">aimor</p>
          <p className="text-center font-playfair text-subtitle text-AIMOR_VIOLET tracking-widest" >You, me & mor</p>
          <Image src={graphic} alt="you should be seeing the graphic here" className='h-60 w-80' />
          <p className="text-center font-gabarito text-lg pb-6 text-AIMOR_VIOLET w-3/5 " >We are using AI to enhance empathic, compassionate conversations to fortify your relationships</p>

          <input
            type="button"
            value="Create account"
            className="input w-[200px] h-[45px]  rounded-lg font-gabarito text-AIMOR_VIOLET bg-white border-AIMOR_VIOLET  text-center transition-all duration-300 ease-in-out transform  hover:scale-110 border-2"
            onClick={signupRedirect}
          ></input>
          <input
            type="button"
            value="Log in"
            className="input w-[200px] h-[45px] border-none rounded-lg font-gabarito text-white bg-AIMOR_VIOLET text-center transition-all duration-300 ease-in-out transform hover:bg-AIMOR_VIOLET_TERTIARY hover:scale-110"
            onClick={logInRedirect}
          ></input>
        
         
          <button
            className="input underline w-[200px] h-[45px] border-none rounded-full font-gabarito text-AIMOR_VIOLET bg-white text-center transition-all duration-300 ease-in-out transformhover:scale-105"
            onClick={guestDashRedirect}

          >
            Continue as guest
          </button>
          <p className="text-center font-playfair font-playfair-900 italic text-AIMOR_VIOLET"  >Sign up for launch updates</p>

          <input
            type="text"
            placeholder="Enter your email address"
            id="filled-basic-dui"
            value={email}
            className="input w-[320px] h-[57px] border-none rounded-full font-gabarito text-AIMOR_VIOLET disabled:text-[#5B45FF] bg-[#5B45FF1A] disabled:bg-[#5B45FF1A] text-center custom-placeholder focus:border-AIMOR_VIOLET focus:outline-AIMOR_VIOLET " // Custom width, height, text color, background color, and border radius
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            disabled={isClicked}
          />

          {showButton && !isClicked &&
            <input
              type="button"
              id="submit-button"
              value={isClicked ? "Thank you for signing up!" : "Submit"}
              className={`input w-[190px] h-[45px] border-none rounded-full font-gabarito text-white bg-AIMOR_VIOLET text-center custom-placeholder ${!isClicked && "transition-all duration-300 ease-in-out transform hover:bg-AIMOR_VIOLET_TERTIARY hover:scale-110"}`} // Custom width, height, text color, background color, and border radius
              onClick={handleClick}
              disabled={isClicked}
            />
          }
          {isClicked &&
            <input
              type="button"
              id="submit-button"
              value={isClicked ? "Thank you for signing up!" : "Submit"}
              className={`input w-[230px] h-[45px] border-none rounded-full font-gabarito text-AIMOR_VIOLET bg-white text-center custom-placeholder ${!isClicked && "transition-all duration-300 ease-in-out transform hover:bg-AIMOR_VIOLET_TERTIARY hover:scale-110"}`} // Custom width, height, text color, background color, and border radius

            />

          }


        {/* </div> */}
      </div>
              
        }

    
     </div>
     {!isLoading && <Footer/>}
    {isLoading && <div className = "p-10"></div>}
    </div>
     
  );
}


export async function getServerSideProps(context: GetServerSidePropsContext) {
  const supabase = createServerClient(context)
  // console.log('fetching user')

  const { data, error } = await supabase.auth.getUser()
  // console.log(data, error)
  if (error || !data) {
    // console.log('error / !data in home', error, data)
  } else if (data.user.id) {


    return {
      props: {},
      // redirect: {
      //   destination: '/userDashboard',
      //   permanent: false,
      // },
    }
  }

  return { props: {} }
}