/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/order */
import logo from "/public/images/logo.svg"
import React from 'react';
// import { useRouter } from 'next/router'
import Image from 'next/image'


const Loading = () => {


    return (
            <div>
                <div className="animate-pulse flex space-x-4 flex-col items-center w-full">
                    <Image src={logo} alt="you should be seeing a logo here" height='200' className='justify-center' />
                </div>
                <div className="text-center font-gabarito text-2xl text-AIMOR_VIOLET pb-8">{`Just one moment...`}</div>
            </div >
            )
}

export default Loading